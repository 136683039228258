<template>
  <n-form
    ref="formRef"
    :inline="inline"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    size="large"
    :label-placement="labelPlacement"
  >
    <slot>Empty form</slot>
  </n-form>
</template>

<script setup lang="ts">
import type { FormRules, NForm } from 'naive-ui';

defineProps({
  formValue: {
    type: Object,
    required: true,
  },
  rules: {
    type: Object as PropType<FormRules>,
    required: true,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  labelPlacement: {
    type: String as PropType<'left' | 'top'>,
    default: 'top',
  },
});

const formRef = ref<InstanceType<typeof NForm> | null>(null);
defineExpose({ formRef });
</script>
<style lang="scss">
.n-form-item-label__asterisk {
  font-family: v-sans;
}

.n-input .n-input-wrapper {
  padding: 0;
}

.n-input .n-input__input-el,
.n-input.n-input--textarea .n-input__textarea-el,
.n-input.n-input--textarea .n-input__placeholder,
.n-input:not(.n-input--textarea) .n-input__placeholder {
  padding-left: var(--n-padding-left);
  padding-right: var(--n-padding-right);
}
</style>
